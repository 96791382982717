.title-container {
  text-align: center;
  padding: 1rem;
  background-color: var(--medium);
  border-radius: 1rem;
}

.title {
  font-family: 'Unbounded';
  font-size: min(6vi, 72px);
  color: var(--primary);
}

.title-container-tertiary {
  background-color: var(--tertiary);
}