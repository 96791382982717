@font-face {
  font-family: "Unbounded";
  src: url("./assets/fonts/Unbounded/Unbounded-VariableFont_wght.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Signika_Negative";
  src: url("./assets/fonts/Signika_Negative/SignikaNegative-VariableFont_wght.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Chivo";
  src: url("./assets/fonts/Chivo/Chivo-VariableFont_wght.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Asap";
  src: url("./assets/fonts/Asap/Asap-VariableFont_wdth\,wght.ttf") format("truetype");
  /* font-weight: 100; */
}

@font-face {
  font-family: "Asap-Thin";
  src: url("./assets/fonts/Asap/static/Asap/Asap-Thin.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "Asap-ExtraLight";
  src: url("./assets/fonts/Asap/static/Asap/Asap-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Asap-Light";
  src: url("./assets/fonts/Asap/static/Asap/Asap-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Asap-Medium";
  src: url("./assets/fonts/Asap/static/Asap/Asap-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Asap-Semibold";
  src: url("./assets/fonts/Asap/static/Asap/Asap-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Asap-Bold";
  src: url("./assets/fonts/Asap/static/Asap/Asap-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Asap-ExtraBold";
  src: url("./assets/fonts/Asap/static/Asap/Asap-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Asap-Black";
  src: url("./assets/fonts/Asap/static/Asap/Asap-Black.ttf") format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "Asap_Italic";
  src: local("Asap"),
   url("./assets/fonts/Asap/Asap-Italic-VariableFont_wdth,wght.ttf") format("truetype");
}

/* primary punch 2.1 */
:root {
  --primary: #1b998b;
  --secondary: #ff715b;
  --tertiary: #bad7f2;
  --white: #FEFEF9;
  --light: #FEFEF9;
  --medium: #f8f4a6;
  --lightgrey: #D1E5F0;
  --mediumgrey: #5EA4CC;
  --darkgrey: #2B6587;
  --dark: #153243;
}

html, body {
  height: 100%;
  width: 100%;
  background-color: var(--light);
}

body {
  margin: 0;
  font-family: 'Asap-Light', sans-serif;
  color: var(--dark);
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  font-family: 'Asap-Medium', sans-serif;
}

a:link, a:visited, a:hover, a:active {
  font-family: 'Asap-Medium';
  color: var(--darkgrey);
  text-decoration-color: var(--tertiary);  
}