.react {
  color: aqua;
}

.angular {
  color: red;
}

.project-tag-container {
  display: flex;
  flex-flow: row wrap;
  gap: .5rem;
  justify-content: flex-end;
}

.project-tag {
  font-size: 14px;
  background-color: var(--light);
  border-radius: 2rem;
  padding: .25rem .5rem;
  display: flex;
  flex-flow: row nowrap;
  gap: .25rem;
  align-items: center;
}

.tag-circle {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.tag-aqua {
  background-color: turquoise;
}

.tag-red {
  background-color: red;
}

.tag-blue {
  background-color: blue;
}

.tag-green {
  background-color:lightgreen;
}

.tag-orange {
  background-color: orange;
}

.tag-yellow {
  background-color: gold;
}

.tag-purple {
  background-color: darkorchid;
}

.tag-pink {
  background-color: fuchsia;
}

.tag-black {
  background-color: black;
}

.tag-coral {
  background-color: lightcoral;
}

.tag-grey {
  background-color: grey;
}