.cah-container {
  padding-bottom: 5rem;
}

@media screen and (min-width: 900px) {
  .cah-section-container {
    padding: 0 8rem;
  }
}
@media screen and (max-width: 900px) {
  .cah-section-container {
    padding: 0 2rem;
  }
}

.cah-designs-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

.cah-designs-header {
  
}

.cah-designs-image {
  max-height: 500px;
}

.cah-designs-logo {
  width: 50vw;
  max-width: 400px;
}

.cah-designs-small-image {
  max-height: 100px;
}