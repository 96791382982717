.h1 {
  color: var(--primary);
  font-size: min(10vw, 48px);
  font-family: 'Unbounded';
  font-weight: 600;
}

/* CSS borrowed from @electrophanteau */
/* headlines with lines */
.decorated{
  overflow: hidden;
  text-align: center;
}
.decorated > span{
 position: relative;
 display: inline-block;
}
.decorated > span:before, .decorated > span:after{
 content: '';
 position: absolute;
 top: 50%;
 border-bottom: 2px solid var(--dark);
 width: 100vw;
 margin: 0 20px;
}
.decorated > span:before{
 right: 100%;
}
.decorated > span:after{
 left: 100%;
}